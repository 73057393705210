.canvas {
    touch-action: none;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

canvas {
    overflow: visible !important;
}

.align-left {
    left: calc(-100vw/1.5);
}

.none {
    display: none;
}

@media screen and (max-width: 700px) {
    .align-left {
        left: calc(-100vw/4);
    }
}