@import '../styles/common.scss';
@import '../styles/variables.scss';



$text-arrow-space: 16px;
$shaft-width: 1px;
$newshaft-width: 64px;
$shaft-thickness: 1px;
$arrow-head-width: 8px;
$arrow-head-thickness: $shaft-thickness;

.layout {
    width: 100vw;
    height: 100%;
    padding: 60px 80px;
    box-sizing: border-box;

    &-header {
        z-index: 100;
        display: flex;
        width: calc(100% - 160px);
        justify-content: space-between;
        align-items: center;
        font-size: 13px;
        position: fixed;
        // backdrop-filter: blur(6px);
        // background-color: rgba(234,228, 243,.15);


        a:visited {
            color: #000000;
        }

        .header-left, .header-nav, .header-media {
          overflow: hidden;
        }

        .header-left {
            font-size: 13px;
            
            &:hover{
              overflow: visible;
            }
      
            a {
                text-decoration: none;
                display: block;
            }

        }

        .header-nav {
            display: flex;
            @extend %gallient-font;

            a {
                color: #000000;
                text-decoration: none;
                margin: 0 35px;
                font-size: 25px;
                display: block;
                transform: translate3d(100, 100 50);

                &:last-child {
                  margin-right: 0px;
                }
            }

            img {
              display: none;
            }
          
        }
        .header-media {
            display: flex;
            flex-direction: row;
            align-items: center;

            .decorate-line {
                max-width: 100px;
                width: calc(40vw - 150px);
                height: 1px;
                border-bottom: solid 1px #000000;
                margin-right: 30px;
            }

            a {
                display: block;
                // text-align: center;
                // width: 20px;
                // height: 20px;
                // border: solid 1px #000000;
                // border-radius: 15px;
                text-decoration: none;
                margin: 0 20px;
                color: #000000;
                // line-height: 25px;

                &:hover {
                  color: $bright-green-color;
                }
            }

            &:last-child {
                margin: 0 0 0 15px;
            }
        }
    }
}


.home-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

// The Arrow
.the-arrow {
    width: $shaft-width;
    transition: all 0.2s;

    &.-left {
      position: absolute;
      top: 60%;
      left: 0;
  
      > .shaft {
        width: 0;
        background-color: $black-color;
        
        &:before,
        &:after {
          width: 0;
          background-color: $black-color;
        }
  
        &:before {
          transform: rotate(0);
        }
  
        &:after {
          transform: rotate(0);
        }
      }
    }
  
    > .shaft {
      background-color: $black-color;
      display: block;
      height: $shaft-thickness;
      position: relative;
      transition: all 0.2s;
      transition-delay: 0;
      will-change: transform;
  
      &:before,
      &:after {
        background-color: $black-color;
        content: '';
        display: block;
        height: $arrow-head-thickness;
        position: absolute;
        top: 0;
        left: 0;
        transition: all .2s;
        transition-delay: 0;
      }
  
      &:before {
        transform-origin: top left;
      }
  
      &:after {
        transform-origin: bottom left;
      }
    }
}
  
// Animated Arrow Button
.animated-arrow {
  display: inline-block;
  color: $black-color;
  text-decoration: none;
  position: relative;
  
  &:hover {
    color: $black-color;
    
    > .the-arrow.-left {
      > .shaft {
        width: $newshaft-width;
        transition-delay: 0.1s;
        background-color: $black-color;

        &:before,
        &:after {
          width: $arrow-head-width;
          transition-delay: 0.1s;
          background-color: $black-color;
        }

        &:before {
          transform: rotate(40deg);
        }

        &:after {
          transform: rotate(-40deg);
        }
      }
    }
    
    > .main {
      transform: translateX($shaft-width + $text-arrow-space);
      transform: translateX($newshaft-width + $text-arrow-space);
    }
  }
  
  > .main {
    display: flex;
    align-items: center;
    transition: all 0.2s;
    
    > .the-arrow {
      position: relative;
    }
  }
}

.white-content {
  .layout-header {
    a {
      color: $white-color !important;
    }

    .decorate-line {
      border-color: $white-color;
    }

    .header-left {
      .the-arrow {
        &.-left {
          > .shaft {
            background-color: $white-color;
            
            &:before,
            &:after {
              background-color: $white-color;
            }
          }
        }
      
        > .shaft {
          background-color: $white-color;
      
          &:before,
          &:after {
            background-color: $white-color;
          }
        }
      }
      
    // Animated Arrow Button
    .animated-arrow {
      color: $white-color;
      
      &:hover {
        color: $white-color;
        
        > .the-arrow.-left {
          > .shaft {
            background-color: $white-color;
    
            &:before,
            &:after {
              background-color: $white-color;
            }
          }
        }
      }
    }
    }
  }
}

.canvasContainer {
  z-index: -100;
  position: relative;
}

@media screen and (max-width: 700px) {
  .layout {
    padding: 60px 40px;

    &-header {
      width: calc(100% - 80px);
      z-index: 15;

      .header-left {

      }
      .header-nav{
        a {
          display: none;
        }
        img {
          display: block;
          width: 30px;
        }
      }
      .header-media {
        .decorate-line {
        }
      }
    }
  }
}

.pseudo-text-effect {
  position: relative;
  padding: 0;
  overflow: hidden;
  transition: transform .5s; //will be inherited
  //transition-timing-function: cubic-bezier(1.000, 0.000, 0.000, 1.000);
  display: block;
  
  &::after {
    content: attr(data-after);
    display: inline-block;
    transform: translateY(100%);
    position: absolute;
    left: 0;
    transition: inherit;
  }
  
  > span {
    display: inline-block;
    transform: translateY(0%);
    transition: inherit;
  }
  
  &:hover {
    > span {
      transform: translateY(-100%);
    }
    &::after {
      transform: translateY(0%);
    }
  }
}