.ring {
    position: fixed;
    top: 0;
    left: 0;
    width: 22px;
    height: 22px;
    border: 1px solid black;
    border-radius: 100%;
    transform: translate(-50%, -50%);
    -webkit-transition-timing-function: ease-out;
    -moz-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    will-change: width, height, transform;
    z-index: 999;
    pointer-events: none;
  }

  .delayRing {
    -webkit-transition-duration: 100ms;
    -moz-transition-duration: 100ms;
    transition-duration: 100ms;
  }
  
  .dot {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    background-color: black;
    border-radius: 100%;
    transform: translate(-50%, -50%);
    z-index: 999;
    pointer-events: none;
  }
  
  .ring.hovered {
    width: 70px;
    height: 70px;
    left: -25px;
    top: -25px;
  }

  .white {
    border-color: white;
    background-color: white;
  }