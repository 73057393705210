svg {
  width: 200px;
  height: 200px;
}

.noise {
  width: 200px;
  height: 200px;
  filter: contrast(170%) brightness(1000%);
  background: linear-gradient(to right, blue, transparent),
  url(https://grainy-gradients.vercel.app/noise.svg);
  mix-blend-mode: multiply;
}