@font-face {
    font-family: 'Gallient';
    font-weight: 400;
    font-style: normal;
    src: local('Gallient'),
        url(../fonts/Gallient.woff) format('woff'),
        url(../fonts/Gallient.ttf) format('truetype'),
}

%gallient-font {
    font-family: 'Gallient', sans-serif;
}
